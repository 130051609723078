<template>
    <div :style="{ height: height, width: width }" ref="chart" />
</template>

<script>
    import geoJson from '../map/china.json'
    export default {
        name: 'echart',
        props: {
            width: {
                type: String,
                default: '100%'
            },
            height: {
                type: String,
                default: '100%'
            },
            options: {
                type: Object,
                default: ()=>({})
            }
        },
        data () {
            return {
                chart: null
            }
        },
        watch: {
            options: {
                handler (options) {
                    // 设置true清空echart缓存
                    this.chart.setOption(options, true)
                },
                deep: true
            }
        },
        mounted () {
            this.initChart();
        },
        methods: {
            initChart () {
                this.$echarts.registerMap('china', geoJson);
                this.chart=this.$echarts.init(this.$refs.chart);
                // 初始化echart
                this.chart.setOption(this.options, true)
                //监听窗口变化
                window.addEventListener("resize",()=>{
                    this.chart.resize();
                });
            }
        }
    }
</script>

<style>
</style>
