<template>
    <div class="app-header">
        <div class="header">
            <dv-decoration-10 :dur="1" style="width:100%;height:5px;transform: rotateY(180deg);align-self: flex-start" />
            <dv-decoration-8 style="width:30%;height:100px;" />
            <div class="header-middle">
                <div class="header-title">{{config.site.name}}</div>
                <dv-decoration-5 style="width:100%;height:65px;"/>
            </div>
            <dv-decoration-8 reverse style="width:30%;height:100px;" />
            <dv-decoration-10 style="width:100%;height:5px;align-self: flex-start" />
            <div class="header-tab1" @click="dialogVisible=true">
                <dv-border-box-8>
                    <span class="desktop">{{title}}</span>
                    <span class="mobile"><i class="el-icon-s-fold"></i></span>
                </dv-border-box-8>
            </div>
            <div class="header-tab2">
                <div class="header-time">{{calendar|timeDate('yyyy/MM/dd')}} {{clock|timeDate('hh:mm:ss')}}</div>
                <el-dropdown @command="handleClick" trigger="click">
                    <el-button size="mini">
                        <span class="desktop">{{$store.state.username}}</span>
                        <span class="mobile"><i class="el-icon-s-unfold"></i></span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="0">选择日期</el-dropdown-item>
                        <el-dropdown-item command="1">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <el-dialog
                title="选择模块"
                :visible.sync="dialogVisible"
                center>
            <div class="model-flex">
                <a v-for="(item,index) in menu" :key="index" @click="handleSelect(index)" class="menu">
                    <div><img :src="item.auth_icon" alt="" width="50px" height="50px"></div>
                    <div>{{item.auth_name}}</div>
                </a>
            </div>
        </el-dialog>
        <el-dialog
                title="选择日期"
                :visible.sync="dialogVisible2"
                center>
            <div class="bigdata-popup">
                <el-date-picker
                        value-format="timestamp"
                        v-model="date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @change="selectDate">
                </el-date-picker>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {formatDate} from "/src/static/js/common";
    import router from '../router'
	import Cookies from "js-cookie";
    export default {
        name:'CustomHeader',
        data() {
            return {
                date:'',
                menu:[],
                clock: new Date(),
                calendar: new Date(),
                week: new Date(),
                dialogVisible:false,
                dialogVisible2:false
            };
        },
        filters: {
            timeDate(date,format) {
                return formatDate(date,format)
            },
            weekDate(value) {
                let week = new Date(value).getDay();
                let str = '';
                switch (week) {
                    case 0:
                        str = '星期日';
                        break;
                    case 1:
                        str = '星期一';
                        break;
                    case 2:
                        str = '星期二';
                        break;
                    case 3:
                        str = '星期三';
                        break;
                    case 4:
                        str = '星期四';
                        break;
                    case 5:
                        str = '星期五';
                        break;
                    case 6:
                        str = '星期六';
                        break;
                }
                return str;
            }
        },
        mounted() {
            this.setClock()//time
			this.setAuth()
        },
        props:{
          title:{
              type: [String],
              default: ''
          }
        },
        methods: {
            handleClick(e){
                if(e==0){
                    this.dialogVisible2=true
                }
                else{
                    this.$confirm('此操作将注销账号, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$store.dispatch('logout').then((res)=>{
                            this.$message({
                                type: 'success',
                                message: '退出成功'
                            });
                        })
                    })
                }
            },
            selectDate(){
                this.$emit('change',{start_time:this.date[0],end_time:this.date[1]})
                this.dialogVisible2=false
            },
            setClock(){
                this.time = setInterval(()=> {
                    this.clock = new Date();
                }, 1000);
            },
            handleSelect(i) {
                this.dialogVisible=false
                router.push(this.menu[i].auth_url);
            },
			
			setAuth(){
				var auth = Cookies.get('auth');
				this.menu = JSON.parse(auth);
			},
        }
    }
</script>
<style lang="scss">
    @media (max-width: 1400px) {
        .header-time{
            display: none;
        }
    }
    //移动端
    @media (max-width: 991px){
        .header-title{
            font-size: 16px !important;
            padding: 0 5px !important;
            height: 5px !important;
            line-height: 5px !important;
        }
        .header{
            height: 5% !important;
        }
        .header .header-tab1 .dv-border-box-8{
            font-size: 12px !important;
            padding: 8px !important;
        }
        .desktop{
            display: none;
        }
    }
    //PC端
    @media (min-width: 992px){
        #app{
            height:100vh;
        }
        .el-dialog__wrapper{
            margin-top: 20vh;
        }
        .mobile{
            display: none;
        }
    }
    *{
        margin: 0;
        padding: 0;
    }
    #app{
        background-image: url("../static/image/bce37a4946becc8b2af52d969cb8993f.png");
        background-repeat: repeat-y;
    }
    .header{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10%;
        position: relative;
        padding-top:15px ;
        .header-middle{
            position: relative;
        }
        .header-title{
            padding: 0 15px;
            color: white;
            letter-spacing:10px;
            text-align: center;
            white-space: nowrap;
            font-weight: bolder;
            font-size: 34px;
            height: 40px;
            line-height: 40px;
        }
        .header-tab{
            text-align: center;
            position: absolute;
            top: 30px;
            color: white;
            font-size: 16px;
        }
        .header-tab1{
            @extend .header-tab;
            left: 5%;
            .dv-border-box-8{
                color: white;
                padding: 10px;
                font-size: 20px;
            }
        }
        .header-tab2{
            @extend .header-tab;
            display: flex;
            align-items: center;
            right: 5%;
            top: 30px;
            text-align: center;
            .header-time{
                font-size: 18px;
                margin-right: 30px;
            }
            button{
               // background-color: transparent;
                border:rgb(35, 95, 167) 1px solid;
                background-color: rgba(35, 95, 167, 0.3);
                color: white;
            }
        }
    }
    //组件样式
    .el-dropdown-menu{
        border:1px solid rgb(35, 95, 167);
        background-color: rgba(35, 95, 167, 0.3);
    }
    .el-dropdown-menu__item{
        color: white;
    }
    .el-popper[x-placement^=bottom] .popper__arrow::after {
        top: 0px;
        border-bottom-color: rgb(35, 95, 167);
    }
    .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
        background-color: rgb(35, 95, 167);
        color: white;
    }
    .dv-decoration-8{
        polyline:nth-of-type(3){
            display: none;
        }
    }
    .el-dropdown-menu__item{
        width: 70px;
    }
    .dv-active-ring-chart{
        width: 100%;
        height: 120px;
    }
    $corner:rgb(46, 96, 153);
    $border:rgb(124, 231, 253);
    .el-dialog{
        //叉号样式
        .el-dialog__headerbtn .el-dialog__close{
            color: white;
        }
        .bigdata-popup{
            width: 100%;
            display: flex;
            justify-content: center;
            z-index: 9;
            .el-input__inner{
                background-color: transparent;
            }
            .el-range-input{
                background-color: transparent;
            }
            .el-range-separator{
                color: white;
            }
        }
        .model-flex{
            display: flex;
            justify-content: space-evenly;
            align-content: center;
            flex-wrap: wrap;
            z-index: 9;
            div{
                margin: 10px 10px 0;
                text-align: center;
            }
        }
        background-color:rgba(35, 95, 167, 0.8);
        //background-image: url("../static/image/bce37a4946becc8b2af52d969cb8993f.png");
        //background-position: 1200px;
        border: 2px solid $border;
        border-radius: 8px;
        z-index: -2;
        &:before {
            position: absolute;
            top: -2px;
            bottom: -2px;
            left: 30px;
            width: calc(100% - 60px);
            content: "";
            border-top: 2px solid $corner;
            border-bottom: 2px solid $corner;
            z-index: -1;
        }
        &:after {
            position: absolute;
            top: 30px;
            right: -2px;
            left: -2px;
            height: calc(100% - 60px);
            content: "";
            border-right: 2px solid $corner;
            border-left: 2px solid $corner;
            z-index: -1;
        }
        .el-dialog__title{
            color: white;
        }
        .el-dialog__body{
            color: white;
        }
        .el-range-input{
            color: white;
        }
    }
    .menu{
        cursor: pointer;
    }
    .dv-decoration-10{
        polyline:nth-of-type(2),polyline:nth-of-type(3),polyline:nth-of-type(4){
            stroke:rgb(63, 150, 165);
        }

    }
</style>
